import { useTranslation } from "@brexhq/i18n";
import type { SearchClient } from "algoliasearch/lite";
import { useAtomValue } from "jotai";
import * as React from "react";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import { useLocation } from "react-router-dom";
import {
  AnalyticsEvents,
  TrackEventOnMount,
  useTrackEvent,
} from "@/domains/App";
import { isContextAwareQueryAtom } from "@/features/SupportHub/atoms/atoms";
import AlgoliaSearchInput from "@/features/SupportHub/components/AlgoliaSearchInput";
import HelpCenterNoSearchResultsBoundary from "@/features/SupportHub/components/HelpCenterNoSearchResultsBoundary";
import {
  SearchContainer,
  SearchInputContainer,
  ArticleListContainer,
} from "@/features/SupportHub/components/HelpCenterSearch.elements";
import InstantSearchHitExternalLink from "@/features/SupportHub/components/InstantSearchHitExternalLink";
import {
  ALGOLIA_SEARCH_DEFAULT_HITS_PER_PAGE,
  ALGOLIA_SEARCH_INITIAL_HITS_PER_PAGE,
} from "@/features/SupportHub/helpers/constants";
import { useMockableInitialAlgoliaQuery } from "@/features/SupportHub/hooks/useInitialAlgoliaQuery";
import type { HitComponent } from "@/features/SupportHub/sharedTypes";

type AlgoliaSearchProps = {
  searchClient: SearchClient;
  indexName: string;
  hitComponent?: HitComponent;
  loading?: boolean;
};
const AlgoliaSearch: React.VFC<AlgoliaSearchProps> = React.memo(
  ({ searchClient, indexName, hitComponent, loading = false }) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();
    const location = useLocation();
    const initialAlgoliaQuery = useMockableInitialAlgoliaQuery(location);
    const isContextAwareQuery = useAtomValue(isContextAwareQueryAtom);

    const handleSeeMoreClick = React.useCallback(() => {
      trackEvent(
        AnalyticsEvents.SupportHubAlgoliaSearchSeeMoreInHelpCenterClicked,
      );
    }, [trackEvent]);

    return (
      <TrackEventOnMount
        eventName={AnalyticsEvents.SupportHubAlgoliaSearchView}
      >
        <SearchContainer>
          <InstantSearch
            searchClient={searchClient}
            indexName={indexName}
            initialUiState={{
              helpCenter: {
                query: initialAlgoliaQuery,
                hitsPerPage: ALGOLIA_SEARCH_INITIAL_HITS_PER_PAGE,
              },
            }}
          >
            <Configure
              // @ts-expect-error - hitsPerPage is not in the types, not sure why TS < 5.6 didn't
              // complain about it
              hitsPerPage={
                isContextAwareQuery
                  ? ALGOLIA_SEARCH_INITIAL_HITS_PER_PAGE
                  : ALGOLIA_SEARCH_DEFAULT_HITS_PER_PAGE
              }
            />
            <SearchInputContainer>
              <AlgoliaSearchInput
                initialAlgoliaQuery={initialAlgoliaQuery}
                loading={loading}
              />
            </SearchInputContainer>
            <ArticleListContainer>
              <HelpCenterNoSearchResultsBoundary>
                <Hits
                  hitComponent={hitComponent}
                  classNames={{
                    list: "HitsContainerList",
                  }}
                />
                <InstantSearchHitExternalLink
                  url="https://www.brex.com/support?utm_source=support-hub"
                  title={t("see-more-in-help-center")}
                  iconName="help"
                  onClick={handleSeeMoreClick}
                />
              </HelpCenterNoSearchResultsBoundary>
            </ArticleListContainer>
          </InstantSearch>
        </SearchContainer>
      </TrackEventOnMount>
    );
  },
);

export default AlgoliaSearch;
