import { useQuery } from "@apollo/client";
import { UserRole } from "@/__generated__/globalTypes";
import { useUserRole } from "@/features/Authentication/contexts/UserProperties";
import type {
  GetSupportHubDataQuery as GetSupportHubDataQueryType,
  GetSupportHubDataQueryVariables,
} from "@/features/SupportHub/data/__generated__/queries.generated";
import { GetSupportHubDataQuery } from "@/features/SupportHub/data/queries";
import { createMockableHook } from "@/helpers/createMockableHook";

const _useGetAllHelpCenterCategoriesQuery = () => {
  const userRole = useUserRole();

  const isAdmin = !!(
    userRole &&
    userRole !== UserRole.EMPLOYEE &&
    userRole !== UserRole.EMPLOYEE_WITHOUT_CORP_CARDS &&
    // USER_MANAGEMENT_ADMIN also do not see representative information
    userRole !== UserRole.USER_MANAGEMENT_ADMIN
  );

  return useQuery<GetSupportHubDataQueryType, GetSupportHubDataQueryVariables>(
    GetSupportHubDataQuery,
    {
      variables: {
        isAdmin,
      },
    },
  );
};

export const [
  useGetAllHelpCenterCategoriesQuery,
  MockGetAllHelpCenterCategoriesQueryProvider,
] = createMockableHook(_useGetAllHelpCenterCategoriesQuery);
