import { LinkButton } from "@brexhq/component-library";
import type { IconName } from "@brexhq/metal-icon";
import { Icon } from "@brexhq/metal-icon";
import { Text } from "@brexhq/metal-text";
import styled from "@emotion/styled";
import * as React from "react";

const LinkContent = styled.div(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  marginLeft: theme.spacing[4],
}));

const ExternalLinkIconWrapper = styled.div(({ theme }) => ({
  marginRight: theme.spacing[4],
}));

type InstantSearchHitExternalLinkProps = {
  title: string;
  url: string;
  iconName?: IconName;
  onClick?: () => void;
};

const InstantSearchHitExternalLink = React.memo(
  ({
    title,
    url,
    iconName = "externalLink",
    onClick,
  }: InstantSearchHitExternalLinkProps) => {
    return (
      <LinkButton href={url} type="link" inline onClick={onClick}>
        <LinkContent>
          <ExternalLinkIconWrapper>
            <Icon name={iconName} size={16} />
          </ExternalLinkIconWrapper>
          <Text variant="BodySmallM">{title}</Text>
        </LinkContent>
      </LinkButton>
    );
  },
);

export default InstantSearchHitExternalLink;
